<template>
	<div class="newmode" v-if="show">
		<div class="cun_inner" :class="{littleWidth:['1','4'].includes(paramsData.type)}" >
			<div class="modeTop">
				<div class="flex_center_align">
					<img class="modeTop_img" :src="require('@/assets/img/'+ paramsData.type +'.png')"
						v-if="paramsData.type" />
					<div class="modeTop_title">{{paramsData.name}}</div>
				</div>
				<div class="modeTop_close" @click="close">
					<img class="mode_img" src="@/assets/img/close1.png" />
				</div>
			</div>
			<div class="mode_content">
				<!-- 民众心声、全民广场 -->
				<div class="mode_content_box" v-if="['2','3'].includes(paramsData.type)">
					<div class="mode_content_item" v-for="(item,index) in articleList" :key="index">
						<div class="mode_content_img" @click="toDetail(item.id)">
							<img v-if="item.articleImg" class="mode_img" :src="item.releaseType==0?baseUrl1+item.articleImg:baseUrl+item.articleImg" />
							<img v-else class="mode_img" src="../assets/img/noImg.jpg" />
							<!-- <video class="mode_img" :src="item.articleImg"></video> -->
						</div>
						<div class="mode_content_title">{{item.articleTitle}}</div>
						<div class="mode_avatar_box">
							<div class="flex_center_align">
								<div class="mode_avatar">
									<img :src="item.avatar" class="mode_img" v-if="item.avatar" />
								</div>
								<div class="mode_name">{{item.fullName}}</div>
							</div>
							<div class="mode_date">{{item.createTime}}</div>
						</div>
					</div>
				</div>

        <!-- 贵客到访 -->
        <div class="mode_content_box" v-if="paramsData.type=='5'">
					<!-- <div class="vipcar" v-for="(item,index) in vipCarList" :key="index" @click="toCarDetail(item)" :class="{'caractive':item.personName}">
						<div class="mode_content_img">
							<img v-if="item.vehiclepicUrl" class="mode_img" :src="baseUrl+item.vehiclepicUrl" />
							<img v-else class="mode_img" src="../assets/img/noImg.jpg" />
						</div>
						<div class="mode_content_title">{{item.personName || ''}}&nbsp;&nbsp;{{item.plateNo || ''}}</div>
						<div class="mode_avatar_box">
							<div class="flex_center_align"> -->

								<!-- <div class="mode_avatar">
									<img :src="item.avatar" class="mode_img" v-if="item.avatar" />
								</div> -->

								<!-- <div class="mode_name">{{item.monitorName || ''}}</div>
							</div>
							<div class="mode_date">{{item.createDate || ''}}</div>
						</div>
					</div> -->

          <el-collapse accordion v-model="itemActive" @change="handleChange">
            <el-collapse-item v-for="(item,index) in vipCarDateList" :key="index" :name="item">
              <template slot="title">
                <div class="title_box">
                  <div class="title_box_left">
                    <div class="one"></div>
                    <div class="two">{{item.days}}</div>
                  </div>
                  <div class="title_box_right">
                    <div class="one">到访数量：{{item.count || 0}}</div>
                    <!-- <div class="two" style="margin-left:20px">人口：</div> -->
                  </div>
                </div>
              </template>

              <div class="box_vipcar" v-loading="loading">
                <div class="_vipcar" v-for="(item,index) in vipCarList" :key="index" @click="toCarDetail(item)" :class="{'caractive':item.personName}">
                  <div class="mode_content_img">
                    <img v-if="item.vehiclepicUrl" class="mode_img" :src="baseUrl+item.vehiclepicUrl" />
                    <img v-else class="mode_img" src="../assets/img/noImg.jpg" />
                  </div>
                  <div class="mode_content_title">{{item.personName || ''}}&nbsp;&nbsp;{{item.plateNo || ''}}</div>
                  <div class="mode_avatar_box">
                    <div class="flex_center_align">
                      <div class="mode_name">{{item.monitorName || ''}}</div>
                    </div>
                    <div class="mode_date">{{item.createDate || ''}}</div>
                  </div>
                </div>
              </div>

            </el-collapse-item>
          </el-collapse>
				</div>

				<!-- 道德银行 -->
				<div v-if="paramsData.type=='4'">
					<div class="mode_bank_item" v-for="(item,index) in banklist" :key="index" @click="seeDetail(item)">
						<div class="flex_center_align">
							<div class="mode_bank_item_img">
								<img class="mode_img" :src="item.avatar" />
							</div>
							<div class="mode_bank_item_title">{{item.reqTitle}}</div>
						</div>
						<div class="flex_center_align">
							<div>{{item.createDate}}</div>
							<div class="mode_bank_item_r">
								<img class="mode_img" src="@/assets/img/rarrow.png" />
							</div>
						</div>
					</div>

				</div>
				<!-- 在线课堂 -->
				<div v-if="paramsData.type=='1'">
					<div class="mode_bank_item" v-for="(item,index) in banklist" :key="index" style="cursor: initial;">
						<div class="flex_center_align">
							<div class="mode_bank_item_img">
								<img class="mode_img" :src="item.avatar|| (baseUrl + item.onlineUserHeadImg)" />
							</div>
							<div class="mode_bank_item_title mode_line_name">
								<span style="margin-right:6px">{{item.areaName}}</span>
								<span>{{item.onlineUserName}}</span>
								在看
								<span>《{{item.readingBookName}}》</span>
							</div>
						</div>
						<div style="color: #ABFFA4;">学习中...</div>
					</div>

				</div>
				<!-- 市场价格-->
				<div class="mode_content_box" v-if="['price'].includes(paramsData.ptype)">
					<!-- 选项 -->
					<div class="flex_center_align" style="margin-left:30px">
						<div>
							<span>省：</span>
							<el-select v-model="cityvalue" placeholder="请选择" @change="cityChange" >
								<el-option v-for="item in cityOptions" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="select_m">
							<span>市场：</span>
							<el-select v-model="market" placeholder="请选择" @change="marketChange" clearable filterable
								style="width:400px">
								<el-option v-for="item in marketOptions" :key="item.marketId" :label="item.marketName"
									:value="item.marketId">
								</el-option>
							</el-select>
						</div>
					</div>
					<!-- 图 -->
					<!-- <div class="chart_box">
						<div ref="priceBar" style="width:550px;height:250px"></div>
						<div ref="pricePie" style="width:350px;height:250px"></div>
					</div> -->
					<!-- 表格 -->
					<div class="mode_price_table">
						<el-table :data="priceList" style="width: 100%" height="480" ref="tab" id="boxed">
							<!-- <el-table-column prop="marketName" label="批发市场" width="100" align="left" />  -->
							<el-table-column prop="goodsName" label="品种" align="center" />
							<el-table-column prop="highestPrice" label="高价" align="center" />
							<el-table-column prop="minimumPrice" label="低价" align="center" />
							<el-table-column prop="middlePrice" label="大宗价" align="center" />
							<!-- <el-table-column prop="provinceName" label="产地" align="center" /> -->
						</el-table>
					</div>
				</div>

			</div>

		</div>


		<div class="newmode detailMode" v-if="detailShow">
			<div class="cun_inner">
				<div class="modeTop">
					<div class="flex_center_align">
						<img class="modeTop_img" :src="require('@/assets/img/'+ paramsData.type +'.png')" />
						<div>{{paramsData.name}}</div>
					</div>
					<div class="modeTop_close" @click="close('detail')">
						<img class="mode_img" src="@/assets/img/close1.png" />
					</div>
				</div>
				<div class="mode_content">
					<!-- 民众心声、全民广场 -->
					<div class="dmode_box" v-if="['2','3'].includes(paramsData.type)">
						<div class="dmode_cover">
							<!-- <img class="mode_img"
								:src="itemData.releaseType==0?baseUrl1:baseUrl + itemData.articleImg" /> -->
              <img v-if="itemData.articleImg" class="mode_img" :src="itemData.releaseType==0?baseUrl1+itemData.articleImg :baseUrl+itemData.articleImg" />
							<img v-else class="mode_img" src="../assets/img/noImg.jpg" />
							<!-- <video class="mode_img" :src="itemData.videoFile" v-if="itemData.videoFile"></video> -->
						</div>
						<div class="mode_article" v-html="itemData.content.articleContent" v-if="itemData.content">
						</div>
						<div class="mode_avatar_box dmode_box">
							<div class="flex_center_align">
								<div class="mode_avatar" v-if="itemData.avatar">
									<img :src="itemData.avatar" class="mode_img" />
								</div>
								<div class="mode_name">{{itemData.fullName}}</div>
							</div>
							<div class="mode_date" style="margin-left:4px">{{itemData.createTime}}</div>
						</div>
					</div>
          <!-- 贵客到访 -->

          <div class="dmode_box" v-if="paramsData.type == 5">
						<div class="dmode_cover">
							<!-- <img class="mode_img"
								:src="itemData.releaseType==0?baseUrl1:baseUrl + itemData.articleImg" /> -->
              <!-- <img v-if="itemData.vehiclePicUrl" class="mode_img" :src="itemData.releaseType==0?baseUrl1+itemData.articleImg :baseUrl+itemData.articleImg" /> -->
              <img v-if="itemData.vehiclepicUrl" class="mode_img" :src="baseUrl+itemData.vehiclepicUrl" />
							<img v-else class="mode_img" src="../assets/img/noImg.jpg" />
							<!-- <video class="mode_img" :src="itemData.videoFile" v-if="itemData.videoFile"></video> -->
						</div>

						<div class="mode_avatar_box dmode_box">
							<div class="flex_center_align">
								<div class="mode_avatar" v-if="itemData.avatar">
									<img :src="itemData.avatar" class="mode_img" />
								</div>
								<div class="mode_name">{{itemData.personName || ''}}&nbsp;&nbsp;{{itemData.plateNo || ''}}&nbsp;&nbsp;{{itemData.vehicleTypeName || ''}}</div>
							</div>
							<div class="mode_date" style="margin-left:4px">{{itemData.monitorName || ''}}&nbsp;&nbsp;{{itemData.createDate || ''}}</div>
						</div>
            <div class="mode_article">
              {{itemData.messageContent}}
						</div>
					</div>

					<!-- 道德银行 -->
					<div class="dmode_box" v-if="paramsData.type == 4">
						<div class="dmode_cover" v-if="itemData.reqImg||itemData.reqVideo">
							<img class="mode_img" :src="baseUrl1 + itemData.reqImg" v-if="itemData.reqImg" />
							<video class="mode_img" :src="itemData.reqVideo" v-if="itemData.reqVideo"></video>
						</div>
						<div class="mode_article" v-if="itemData.reqContent">{{itemData.reqContent}}</div>
						<div class="mode_avatar_box dmode_box">
							<div class="flex_center_align">
								<div class="mode_avatar" v-if="itemData.avatar">
									<img :src="itemData.avatar" class="mode_img" />
								</div>
								<div class="mode_name">{{itemData.fullName}}</div>
							</div>
							<div class="mode_date" style="margin-left:4px">{{itemData.createDate}}</div>
						</div>
					</div>
				</div>

			</div>
		</div>




		<wg-mode ref="wgMode"></wg-mode>
	</div>
</template>

<script>
import WgMode from './wgMode.vue'
import { getArticleInfo, searchMarkets, searchPriceList, vipCarList } from '@/api/api.js'
import { Loading } from 'element-ui'
import { setTimeout } from 'timers'
import { baroption } from '@/utils/interoptions.js'
import * as echarts from 'echarts'
// import { url } from 'inspector'
export default {
  components: {
    WgMode
  },
  data() {
    return {
      show: false,
      paramsData: {},
      queryParams: {
        page: 1,
        rows: 10,
        loadMoreText: '加载中...',
        showLoadMore: false,
        total: '',
        newTotal: '',
        nodata: false
      },
      articleList: [],
      vipCarDateList: [],
      vipCarList: [],
      loading: false,
      itemActive: '',
      detailShow: false,
      itemData: false,
      baseUrl: 'https://xiaopingfang.baize-agri.cn/prod/api',
      baseUrl1: 'https://xiaopingfang.baize-agri.cn/prod/wx', //小程序端发布
      banklist: [],
      cityOptions: [],
      cityvalue: '',
      marketOptions: [],
      market: '',
      priceList: []
    }
  },
  methods: {
    handleChange(val) {
      // console.log('-------val-----', val)
      this.loading = true
      if (val) {
        this.vipCarList = []
        let params = {
          // regionId: this.paramsData.regionId,
          // areaManagementId: val,
          createDate: val.days
        }
        vipCarList(params).then(res => {
          if (res.code == '0') {
            // res.model.map(item => {
            //   if (item.avatarUrl) item.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + item.avatarUrl
            // })
            this.vipCarList = res.model
            this.loading = false
          } else {
            this.loading = false
          }
        })
      } else {
        this.vipCarList = []
        this.loading = false
      }
    },
    sexFormat(row, column) {
      if (row.memberSex == '1') {
        return '男'
      } else {
        return '女'
      }
    },
    initData(data) {
      // console.info('zujian', data)
      this.paramsData = data.form

      if (['3', '2'].includes(data?.form.type)) {
        console.log(data.form)
        this.articleList = data?.articleList
      }
      if (data.form.type == '4') {
        this.banklist = data.list
      }
      if (data.form.type == '1') {
        this.banklist = data.list
      }
      if (data.form.type == '5') {
        this.vipCarDateList = data.list
        this.itemActive = data.list[0]
        this.handleChange(data.list[0])
      }
      if (data.form.ptype == 'price') {
        this.searchMa()
        // 默认查询朝阳市市场
        // this.marketChange('76E4F160C04F936CE040A8C020017257')
        // setTimeout(() => {
        //   this.drowEcharts()
        // }, 2000)
      }
      this.show = true
    },
    close(type) {
      if (type == 'detail') {
        this.detailShow = false
        this.itemData = {}
      } else {
        // else if (type == 'party') {
        //   // this.partyShow = false
        //   this.partyData = []
        // }
        this.show = false
        //   this.numsForm = []
        //   this.peopleList = []
        this.paramsData = {}
        this.articleList = []
        this.marketOptions = []
        this.market = ''
        this.priceList = []
      }
    },
    // 查看详情
    toDetail(id) {
      getArticleInfo(id).then(res => {
        if (res.code == '0') {
          this.itemData = res.model
          console.log(res.model)
          this.$nextTick(() => {
            this.detailShow = true
          })
        }
      })
    },
    toCarDetail(item) {
      this.itemData = item
      this.$nextTick(() => {
        this.detailShow = true
      })
    },
    seeDetail(item) {
      this.itemData = item
      this.detailShow = true
    },
    searchMa() {
      searchMarkets().then(res => {
        if (res.code == '0') {
          res.model.forEach(item => {
            item.value = item.id
            item.label = item.name
          })
          this.cityOptions = res.model
          // 默认辽宁省
          this.cityvalue =
            res.model.filter(i => {
              return i.key.includes('辽宁省')
            })[0].key || res.model[0].key
          this.cityChange(this.cityvalue)
        }
      })
    },
    marketChange(e) {
      this.serachPrice(e)
    },
    cityChange(e) {
      // 根据选择的省份过滤市场
      let arr = this.cityOptions.filter(item => {
        return item.key == e
      })[0].data
      this.market = ''
      if (arr.length > 0) {
        arr.forEach(i => {
          i.value = i.marketId
          i.label = i.marketName
        })
        this.marketOptions = arr
        //市场默认
        this.market = arr[0].value
        this.marketChange(this.market)
      }
    },
    serachPrice(mid) {
      this.priceList = []
      searchPriceList({
        marketId: mid
      }).then(res => {
        if (res.code == '0') {
          this.priceList = res.model
        }
      })
    },
    drowEcharts() {
      baroption.xAxis.data = ['玉米', '黄瓜', '西红柿', '豆角', '香菜', '玉米', '黄瓜', '西红柿', '豆角', '香菜']
      baroption.series[0].data = [200, 300, 400, 500, 600, -100, -200, -300, -400, -500]
      let chart = echarts.init(this.$refs.priceBar, null, {
        renderer: 'svg'
      })
      chart.clear()
      chart.setOption(baroption)

      let pieoption = {
        title: {
          text: '各市场菜品占有量统计图',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#F9B814', '#9523CB'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['常住人口', '外来人口', '流动人口']
        },
        series: [
          {
            name: '人口',
            type: 'pie',
            radius: ['65%', '80%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [
              {
                value: 20,
                name: '常住人口'
              },
              {
                value: 32,
                name: '外来人口'
              },
              {
                value: 4,
                name: '流动人口'
              }
            ]
          }
        ]
      }
      let cricle = echarts.init(this.$refs.pricePie, null, {
        renderer: 'svg'
      })
      cricle.clear()
      cricle.setOption(pieoption)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-loading-mask {
  padding-top: 50px;
  background-color: rgba(0, 0, 0, 0);
}

.el-collapse {
  width: 100%;
  // margin: 0 1% ;
  border: none;

  .el-collapse-item {
    //  background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);
    margin-bottom: 5px;
  }
  /deep/ .el-collapse-item__header {
    background: linear-gradient(90deg, #182d68 0%, rgba(13, 24, 54, 0) 100%);
    color: #ffffff;
    border: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  // .el-collapse-item__header{
  //   background-color: #adffe6;
  // }
  .title_box {
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title_box_left,
    .title_box_right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .title_box_left {
      .one {
        height: 6px;
        width: 6px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 13px 1px rgba(255, 255, 255, 1);
        margin: 0 11px;
      }
    }
  }
  /deep/ .el-collapse-item__arrow {
    margin: 0 !important;
  }
  /deep/.el-collapse-item__wrap {
    background-color: inherit;
    border: none;
  }

  /deep/.el-collapse-item__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 100px;
  }
  .item_box {
    width: 259px;
    height: 94px;
    background: linear-gradient(90deg, #12224d 0%, rgba(13, 24, 54, 0) 100%);
    margin-top: 20px;
    margin-right: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 15.5px 17px;

    .img {
      display: block;
      width: 53px;
      height: 63px;
      margin: 0 10px 0 17px;
    }
    .user {
      // margin-top: 16px;
      height: 63px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .user_name {
        display: flex;
        flex-direction: row;
        align-items: center;

        div {
          height: 20px;
          color: #fff;
        }
        div:nth-child(1) {
          font-family: Source Han Sans CN;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
          margin-right: 14px;
        }
        div:nth-child(2) {
          font-family: Source Han Sans CN;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
      .user_add {
        font-family: Source Han Sans CN;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        height: 36px;
        width: 176px;
        overflow: hidden;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  .item_box:nth-child(4n) {
    margin-right: 0;
  }
  .item_box:last-child {
    margin-right: auto;
  }
}

.box_vipcar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ._vipcar {
    width: 23%;
    border-radius: 10px;
    overflow: hidden;
    background: #00000026;
    margin: 10px 1% 0;
    color: #fff;
  }
}

.vipcar {
  width: 254px;
  // height: 219px;
  border-radius: 10px;
  overflow: hidden;
  background: #00000026;
  margin: 0 20px 20px 0;
  .caractive {
    color: rgb(77, 185, 165);
  }
}
.newmode {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  color: #fff;

  .mode_img {
    width: 100%;
    height: 100%;
  }

  .cun_inner {
    width: 1139px;
    height: 734px;
    position: absolute;
    left: 391px;
    top: 173px;
    z-index: 1000;
    padding: 20px 10px;
    border-radius: 10px;
    animation-name: small-to-big;
    animation-duration: 1s;
    animation-fill-mode: forwards;

    @keyframes small-to-big {
      from {
        transform: scale(0);
      }

      to {
        transform: scale(1);
      }
    }

    .modeTop {
      width: 100%;
      height: 42px;
      background-image: url('../assets/img/titlebg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: JDLangZhengTi;
      font-size: 26px;
      font-weight: 400;
      margin-bottom: 7px;
      &_title {
        margin-left: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // width: 800px;
      }

      &_img {
        margin-left: 14px;
      }

      &_close {
        width: 45px;
        height: 45px;
        margin-right: 8px;
      }
    }

    .mode_content {
      width: 100%;
      height: 685px;
      overflow-y: scroll;
      background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);
      padding-bottom: 10px;

      &_box {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 26px 19px;
        width: calc(100% - 38px);
      }

      &_item {
        width: 254px;
        height: 219px;
        border-radius: 10px;
        overflow: hidden;
        background: #00000026;
        margin: 0 20px 20px 0;
      }

      &_img {
        width: 254px;
        height: 149px;
        border-radius: 10px 10px 0px 0px;
        width: 100%;
        height: 149px;
        border-radius: 10px 10px 0 0;
      }

      &_title {
        font-family: SourceHanSansCN;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        margin: 12px 10px 8px;
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 溢出部分隐藏 */
        text-overflow: ellipsis;
        /* 超出部分显示省略号 */
      }

      .mode_avatar_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;

        .mode_avatar {
          width: 14px;
          height: 14px;
          border-radius: 2px;
          overflow: hidden;
          margin: 0 4px 0 10px;
        }

        .mode_date {
          margin-right: 10px;
        }
      }
    }

    .mode_bank_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 410px;
      height: 45px;
      background: linear-gradient(90deg, #182d68 0%, rgba(13, 24, 54, 0) 100%);
      margin: 13px auto 0;
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      &_img {
        width: 28px;
        height: 28px;
        border-radius: 5px;
        overflow: hidden;
        margin-left: 10px;
      }

      &_title {
        margin-left: 7px;
      }

      &_r {
        width: 35px;
        height: 35px;
      }
    }

    .mode_line_name {
      width: 300px;
      white-space: nowrap;
      /* 不换行 */
      overflow: hidden;
      /* 超出部分隐藏 */
      text-overflow: ellipsis;
      /* 使用省略号显示 */
    }

    .select_m {
      margin-left: 40px;
    }

    .mode_price_table {
      width: 96%;
      margin: 40px auto 20px;
    }
  }

  .littleWidth {
    width: 471px;
    height: 734px;
    left: 725px;
  }

  .chart_box {
    width: 100%;
    margin: 50px auto 0;
    display: flex;
    justify-content: space-around;
  }
}

.detailMode {
  z-index: 1000;

  .dmode_box {
    width: 1073px;
    height: auto;
    margin: 26px auto 0;

    .dmode_cover {
      width: 100%;
      height: auto;
      border-radius: 10px 10px 0px 0px;

      img,
      video {
        border-radius: 10px 10px 0px 0px;
      }
    }

    .mode_article {
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      margin: 13px 10px;
    }

    .dmode_box {
      justify-content: flex-start !important;
      margin-top: 10px;
    }
  }
}

.mode_content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.mode_content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #0d53b7;
  -webkit-box-shadow: inset 0 0 5px #0d53b7;
  background: #535353;
}

.mode_content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}
</style>
<style lang="scss">
.el-table {
  background-color: transparent !important;
}

.el-table th.el-table__cell,
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: transparent !important;
}

.el-table tr {
  background-color: transparent !important;
}

.el-table .cell {
  color: #fff;
}

.el-table th.el-table__cell > .cell {
  color: #fff;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
  /* color: #f19944; */
  /* 设置文字颜色，可以选择不设置 */
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: transparent !important;
}

.el-table .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px !important;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px !important;
}

.el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  background: #535353 !important;
}

.el-table .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px !important;
  background: #ededed !important;
}

.mode_price_table .el-table th.el-table__cell {
  color: #ceb77b !important;
  // font-size: 14px !important;
}

.mode_price_table .el-table thead th.el-table__cell .cell {
  color: #ceb77b !important;
  // font-size: 14px !important;
}
</style>
